import React from "react";

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
  myTextColor,
}) => {
  return (
    <div className={`section-heading ${textAlignment} ${textColor}`}>
      <div
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></div>
      <h2
        style={myTextColor}
        className="title color-gray-4"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
};

export default SectionTitle;
