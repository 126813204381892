import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpOne = () => {
  return (
    <div className="section section-padding bg-color-dark">
      <div className="container">
        <SectionTitle
          subtitle="Tout ce dont vous avez besoin"
          title="Laissez votre personnel se concentrer sur le plaisir des clients
          et laissez-nous le reste."
          description="Avec SmartFy Order & Pay, vos clients peuvent accéder aux menus et commander via un code QR personnalisé."
          textAlignment="heading-light"
          textColor=""
        />
        <div className="row">
          <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
        </div>
      </div>
    </div>
  );
};

export default CounterUpOne;
