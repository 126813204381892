import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "ACCÉLÉREZ LE PAIEMENT",
    para: "Acceptez les moyens de paiements les plus utilisés par vos clients.",
  },
  {
    id: 2,
    title: "RÉCOMPENSEZ LA FIDÉLITÉ DE VOS CLIENTS",
    para: "Tirez parti des données de vos ventes pour mettre en place des programmes de fidélité.",
  },
  {
    id: 3,
    title: "OFFREZ UNE EXPÉRIENCE UNIQUE",
    para: "Préservez vos équipes et développez de ventes additionnelles grâce à une expérience unique en salle",
  },
];

const AboutThree = () => {
  return (
    <div className="section  bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Nos Valeurs"
          title="SCANNEZ. COMMANDEZ. PAYEZ. RECOMMENCEZ."
          description="Avec SmartFy Order & Pay, vos clients peuvent accéder aux menus et commander via un code QR personnalisé."
          textAlignment="heading-center heading-light mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4  " key={data.id}>
              <div className="about-quality d-flex flex-column align-items-center">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
