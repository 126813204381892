import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerFour from "../component/banner/BannerFour";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFour from "../component/project/ProjectFour";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CounterUpThree from "../component/counterup/CounterUpThree";
import AboutThree from "../component/about/AboutThree";
import ProcessOne from "../component/process/ProcessOne";
import CaseDetails from "./CaseStudyDetails";
import CaseStudyProp from "../component/casestudy/CaseStudyProp";

const HomeStartup = () => {
  return (
    <>
      <SEO title="Acceuil SmartFy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerFour />
        <CounterUpThree />

        <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
          <div className="container">
            <CaseStudyProp />
          </div>
        </div>

        <CounterUpOne />
        <AboutThree />
        {/* <CaseDetails /> */}

        <CtaLayoutOne />
        <FooterOne />
      </main>
    </>
  );
};

export default HomeStartup;
