import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    id: 3,
    light_icon: "/images/icon/rating.svg",
    dark_icon: "/images/icon/rating.svg",
    countNum: 3,
    text: "Sublimez l'expérience de vos clients",
    symbol: "x",
  },
  {
    id: 1,
    light_icon: "/images/icon/revenu.svg",
    dark_icon: "/images/icon/revenu.svg",
    countNum: 27,
    text: "Maximisez votre revenu par client",
    symbol: "%",
  },
  {
    id: 2,
    light_icon: "/images/icon/operation.svg",
    dark_icon: "/images/icon/operation.svg",
    countNum: 45,
    text: "Simplifiez vos opérations",
    symbol: "%",
  },

  {
    id: 4,
    light_icon: "/images/icon/data.svg",
    dark_icon: "/images/icon/data.svg",
    countNum: 10,
    text: "Exploitez le potentiel de la data",
    symbol: "Minutes",
  },
];

const CounterUp = ({ colSize, layoutStyle, evenTopMargin }) => {
  return (
    <>
      {Data.map((data) => (
        <div
          className={`${colSize} ${data.id % 2 === 0 ? evenTopMargin : ""}`}
          key={data.id}
        >
          <div className={`counterup-progress ${layoutStyle}`}>
            {layoutStyle === "counterup-style-2" ? (
              <div className="icon">
                <img
                  className="dark-icon"
                  src={process.env.PUBLIC_URL + data.dark_icon}
                  alt="Icon"
                />
                <img
                  className="light-icon"
                  src={process.env.PUBLIC_URL + data.light_icon}
                  alt="Icon"
                />
              </div>
            ) : (
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + data.light_icon}
                  alt="Icon"
                />
              </div>
            )}

            <h6 className="title">{data.text}</h6>
          </div>
        </div>
      ))}
    </>
  );
};

export default CounterUp;
