import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUpIcon from "../../component/counterup/CounterUpIcon";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpThree = () => {
  return (
    // <div className="section section-padding bg-color-black">
    //   <div className="container">
    //     <SectionTitle
    //       subtitle=""
    //       title="75 % des clients souhaitent utiliser leur smartphone chez vous"
    //       description="Proposez leur votre Web App sur mesure, sans téléchargement, accessible via QR code
    //       "
    //       textAlignment="heading-light"
    //       textColor=""
    //       myTextColor={{ color: "black" }}
    //     />
    //     <div className="case-study-featured-thumb text-start">
    //       <img
    //         src={process.env.PUBLIC_URL + "/images/others/fleche.png"}
    //         alt="travel"
    //       />
    //     </div>
    //     <div className="row">
    //       <CounterUpIcon
    //         colSize="col-lg-3 col-6"
    //         layoutStyle=""
    //         evenTopMargin=""
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className="section section-padding bg-color-dark">
      <div className="container">
        <SectionTitle
          subtitle=""
          title="75 % des clients souhaitent utiliser leur smartphone chez vous"
          description="Proposez leur votre Web App sur mesure, sans téléchargement, accessible via QR code"
          textAlignment="heading-light"
          textColor=""
        />
        <div
          className="case-study-featured-thumb text-start flecheImg"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/banner/screenPhone3.png"}
            alt="travel"
            style={{ width: "100%" }}
          />
        </div>
        <div className="case-study-featured-thumb text-start flecheImg">
          <img
            src={process.env.PUBLIC_URL + "/images/others/fleche.png"}
            alt="travel"
            style={{ width: "80%" }}
          />
        </div>
        <div className="row">
          <CounterUpIcon
            colSize="col-lg-3 col-6"
            layoutStyle=""
            evenTopMargin=""
          />
        </div>
      </div>
    </div>
  );
};

export default CounterUpThree;
